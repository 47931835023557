export const SPORT_TYPES = {
  AMERICAN_FOOTBALL: "americanFootball",
  BEACH_VOLLEYBALL: "beachvolleyball",
  FLOORBALL: "floorball",
  ICE_HOCKEY: "icehockey",
  ROLLER_HOCKEY: "rollerhockey",
  SOFTBALL: "softball",
  WATERPOLO: "waterpolo",
  BADMINTON: "badminton",
  BOWLING: "bowling",
  FUTSAL: "futsal",
  LACROSSE: "lacrosse",
  RUGBY: "rugby",
  SWIMMING: "swimming",
  WRESTLING: "wrestling",
  BASEBALL: "baseball",
  CRICKET: "cricket",
  GYMNASTICS: "gymnastics",
  NETBALL: "netball",
  SNOOKER: "snooker",
  TENNIS: "tennis",
  BASKETBALL: "basketball",
  FIELD_HOCKEY: "fieldhockey",
  HANDBALL: "handball",
  OTHER: "other",
  GENERAL: "general",
  SOCCER: "soccer",
  FOOTBALL: "football",
  VOLLEYBALL: "volleyball",
  FLAG_FOOTBALL: "flagfootball",
} as const;
